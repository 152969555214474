var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (this.$route.name === 'GetStarted'
          || this.$route.name === 'InjuredInformation'
          || this.$route.name === 'OtherInsurance'
          || this.$route.name === 'AccidentInformation'
          || this.$route.name === 'MedicalRelease'
          || this.$route.name === 'FraudWarning'
          || this.$route.name === 'SupportingDocuments')?_c('v-row',{staticClass:"pt-2",attrs:{"data-cy":"mobile-stepper"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"color":"#5c77df","height":"85px"}},[_c('v-list-item',{staticClass:"pl-9 pr-9 mt-n2",attrs:{"two-line":""}},[_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"stepper-title",attrs:{"data-cy":"mobile-stepper-current-page"}},[_vm._v(" "+_vm._s(_vm.$route.name === 'GetStarted' ? 'Getting Started' : _vm.$route.name === 'InjuredInformation' ? 'Claimant Information' : _vm.$route.name === 'OtherInsurance' ? 'Other Insurance' : _vm.$route.name === 'AccidentInformation' ? 'Claim Information' : _vm.$route.name === 'MedicalRelease' ? 'Medical Records Release' : _vm.$route.name === 'FraudWarning' ? 'Fraud Warning Notices' : _vm.$route.name === 'SupportingDocuments' ? 'Supporting Documents' : '')+" ")]),_c('v-list-item-subtitle',{staticClass:"stepper-subtitle",attrs:{"data-cy":"mobile-stepper-next-page"}},[_vm._v(" "+_vm._s(_vm.$route.name === 'GetStarted' ? 'Next: Claimant Information' : _vm.$route.name === 'InjuredInformation' ? 'Next: Other Insurance' : _vm.$route.name === 'OtherInsurance' ? 'Next: Claim Information' : _vm.$route.name === 'AccidentInformation' ? 'Next: Medical Records Release' : _vm.$route.name === 'MedicalRelease' ? 'Next: Fraud Warning Notices' : _vm.$route.name === 'FraudWarning' ? 'Next: Supporting Documents' : _vm.$route.name === 'SupportingDocuments' ? '' : '')+" ")])],1),_c('v-list-item-avatar',{staticClass:"mt-3",attrs:{"size":"60","color":"#5c77df"}},[_c('v-progress-circular',{attrs:{"rotate":-90,"size":60,"width":5,"value":_vm.$route.name === 'GetStarted' ? 14 :
                    _vm.$route.name === 'InjuredInformation' ? 28 :
                    _vm.$route.name === 'OtherInsurance' ? 43 :
                    _vm.$route.name === 'AccidentInformation' ? 56 :
                    _vm.$route.name === 'MedicalRelease' ? 71 :
                    _vm.$route.name === 'FraudWarning' ? 84 :
                    _vm.$route.name === 'SupportingDocuments' ? 100 : 0,"color":"#8bde3b"}},[_c('span',{staticClass:"stepper-numbers",attrs:{"data-cy":"mobile-stepper-page-number"}},[_vm._v(" "+_vm._s(_vm.$route.name === 'GetStarted' ? '1 of 7' : _vm.$route.name === 'InjuredInformation' ? '2 of 7' : _vm.$route.name === 'OtherInsurance' ? '3 of 7' : _vm.$route.name === 'AccidentInformation' ? '4 of 7' : _vm.$route.name === 'MedicalRelease' ? '5 of 7' : _vm.$route.name === 'FraudWarning' ? '6 of 7' : _vm.$route.name === 'SupportingDocuments' ? '7 of 7' : '')+" ")])])],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }