import axiosInstance from '@/utils/axios-instance'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { FnolSubmission } from '@/models/FnolSubmission'
import { AccidentInformation } from '@/models/AccidentInformation'
import { AdditionalInformation } from '@/models/AdditionalInformation'
import { InjuredInformation } from '@/models/InjuredInformation'
import { InjuredPersonInformation } from '@/models/InjuredPersonInformation'
import { ContactPersonInformation } from '@/models/ContactPersonInformation'
import { OtherInsurance } from '@/models/OtherInsurance'
import { FileUploadResponse } from '@/models/contract/FileUploadResponse'
import { MedicalRelease } from '@/models/MedicalRelease'


export class FnolApiClient {

  private API_RECAPTCHA_KEY_URL = '/api/recaptcha/key'
  private API_RECAPTCHA_VERIFY_URL = '/api/recaptcha/verify'
  private API_CREATE_FNOL_URL = '/api/fnol/create'
  private API_UPDATE_FNOL_URL = '/api/fnol/update'
  private API_CANCEL_FNOL_URL = '/api/fnol/cancel'
  private API_SUBMIT_FNOL_URL = '/api/fnol/submit'
  private API_FILE_UPLOAD_URL = '/api/files/upload'
  private API_FILE_REMOVE_URL = '/api/files/remove'

  async getKey(): Promise<string | void> {

    return axiosInstance.post(this.API_RECAPTCHA_KEY_URL).then((response: AxiosResponse<string>) => {

      if (response?.status == 200) {
        return response.data
      } else if (response?.status != 200) {
        console.error(response.statusText)
        //TODO HANDLE OTHER STATUSES
      }

    }).catch(error => {
      console.error(error)
      //TODO HANDLE CLIENT UNEXPECTED ERRORS
    })

  }

  async isTokenValid(token: string): Promise<boolean | void> {

    return axiosInstance.post(this.API_RECAPTCHA_VERIFY_URL, token).then((response: AxiosResponse<boolean>) => {

      if (response?.status == 200) {
        return response.data
      } else if (response?.status != 200) {
        console.error(response.statusText)
        //TODO HANDLE OTHER STATUSES
      }

    }).catch(error => {
      console.error(error)
      //TODO HANDLE CLIENT UNEXPECTED ERRORS
    })

  }

  async createNewSubmission(): Promise<FnolSubmission | void> {

    return axiosInstance.post(this.API_CREATE_FNOL_URL).then((response: AxiosResponse<FnolSubmission>) => {

      if (response?.status == 200) {
        return response.data
      }

    }).catch(error => {
      console.error(error)
      //TODO HANDLE CLIENT UNEXPECTED ERRORS
    })
  }

  async saveSubmission(submission: FnolSubmission): Promise<FnolSubmission | void> {

    return axiosInstance.put(this.API_UPDATE_FNOL_URL, submission).then((response: AxiosResponse<FnolSubmission>) => {

      if (response?.status == 200) {
        return response.data
      }

      //Validation errors return a 400, return null so the user cannot navigate
      if(response?.status == 400) {
        return null
      }

    }).catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error)) {
        console.error(error.response?.data)

        //TODO HANDLE CLIENT UNEXPECTED ERRORS
      } else {
        console.error(error)
      }
    })
  }

  async cancelSubmission(): Promise<FnolSubmission | void> {

    return axiosInstance.post(this.API_CANCEL_FNOL_URL).then((response: AxiosResponse<void>) => {

      if (response?.status == 200) {
        return new FnolSubmission()
      }

    }).catch(error => {
      console.error(error)
      //TODO HANDLE CLIENT UNEXPECTED ERRORS
    })
  }

  async submitSubmission(): Promise<void> {

    await axiosInstance.post(this.API_SUBMIT_FNOL_URL).then((response: AxiosResponse<void>) => {

      if (response?.status == 200) {
        return
      }

    }).catch(error => {
      console.error(error)
      //TODO HANDLE CLIENT UNEXPECTED ERRORS
    })
  }

  initializeSubmission(submission: FnolSubmission): FnolSubmission {

    if (!submission.accidentInformation) {
      submission.accidentInformation = new AccidentInformation()
    }

    if (!submission.medicalRelease) {
      submission.medicalRelease = new MedicalRelease()
    }

    if (!submission.additionalInformation) {
      submission.additionalInformation = new AdditionalInformation()
    }

    if (!submission.injuredInformation) {
      submission.injuredInformation = new InjuredInformation()
      submission.injuredInformation.injuredPersonInformation = new InjuredPersonInformation()
      submission.injuredInformation.contactPersonInformation = new ContactPersonInformation()
    }

    if (!submission.otherInsurance) {
      submission.otherInsurance = new OtherInsurance()
    }

    if (!submission.uploadedFileStatuses) {
      submission.uploadedFileStatuses = []
    }
    return submission
  }

//FILE UPLOAD API

  async uploadFile(file: File): Promise<FileUploadResponse | void> {
    const formData = new FormData();
    formData.append("file", file);

    return await axiosInstance.request<FileUploadResponse>({
      timeout: 60000,
      method: 'put',
      url: this.API_FILE_UPLOAD_URL,
      data: formData,
      responseType: 'json',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response: AxiosResponse<FileUploadResponse>) => {

      if (response?.status == 200) {
        return response.data
      }

    }).catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error)) {
        console.error(error.response?.data)
        //TODO HANDLE CLIENT UNEXPECTED ERRORS
      } else {
        console.error(error)
      }
      // For now, re-throw the same error
      // TODO: In the future, throw whatever error makes sense
      throw error
    });
  }

  async removeFile(filePath: string): Promise<string | void> {

    return await axiosInstance.request<string>({
      timeout: 10000,
      method: 'delete',
      url: this.API_FILE_REMOVE_URL,
      data: filePath,
      responseType: 'json',
      headers: {
        'Content-Type': 'text/plain'
      }
    }).then((response: AxiosResponse<string>) => {

      if (response?.status == 204) {
        return response.data
      }

    }).catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error)) {
        console.error(error.response?.data)
        //TODO HANDLE CLIENT UNEXPECTED ERRORS
      } else {
        console.error(error)
      }
    });
  }

}
