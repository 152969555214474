export class InjuredPersonInformation {
  firstName!: string
  lastName!: string
  suffix!: string
  suffixCode!: string
  addressLine1!: string
  addressLine2!: string
  addressCity!: string
  addressState!: string
  addressStateCode!: string
  addressZip!: string
  phoneNumber!: string
  emailAddress!: string
  dateOfBirth!: string
  gender!: string
  genderCode!: string
}
