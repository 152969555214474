import Vue from 'vue'
import Vuex from 'vuex'
import { FnolApiClient } from '@/client/FnolApiClient'
import { FnolSubmission } from '@/models/FnolSubmission'
import { FileUploadResponse } from '@/models/contract/FileUploadResponse'
import UploadedFileStatus from '@/models/UploadedFileStatus'
import { CommonData } from '@/models/CommonData'
import { CommonContact } from '@/models/CommonContact'

Vue.use(Vuex)

const fnolApiClient = new FnolApiClient()

export default new Vuex.Store({

  state: {
    siteKey: '',
    isRecaptchaTokenValid: false,
    loadRecaptchaWidget: false,
    submission: new FnolSubmission(),
    savingData: false,
    navigating: false,
    navigatingNext: false,
    navigatingPrevious: false,
    startingOver: false,
    canUserNavigate: false,
    totalMB: 0
  },
  actions: {
    async getKey({commit}): Promise<string | void> {
      await fnolApiClient.getKey().then(response => {
        commit('setKey', response)
      })
    },
    async verifyToken({commit}, token: string): Promise<boolean | void> {
      await fnolApiClient.isTokenValid(token).then(response => {
        commit('setIsRecaptchaTokenValid', response)
      })
    },
    async createNewSubmission({commit}): Promise<FnolSubmission | void> {
      commit('setSavingData', true)
      await fnolApiClient.createNewSubmission().then(response => {
        if (response) {
          const fnolSubmission = fnolApiClient.initializeSubmission(<FnolSubmission>response)
          commit('setSubmission', fnolSubmission)
        }
      }).finally(() => {
        commit('setSavingData', false)
      })
    },
    async saveSubmission({commit}, submission: FnolSubmission): Promise<FnolSubmission | void> {
      commit('setSavingData', true)
      await fnolApiClient.saveSubmission(submission).then(response => {
        if (response) {
          const fnolSubmission = fnolApiClient.initializeSubmission(<FnolSubmission>response)
          commit('setSubmission', fnolSubmission)
          commit('setCanUserNavigate', true)
        }
        else {
          commit('setCanUserNavigate', false)
        }
      }).finally(() => {
        commit('setSavingData', false)
      })
    },
    async cancelSubmission({commit}): Promise<FnolSubmission | void> {
      commit('setSavingData', true)
      await fnolApiClient.cancelSubmission().then(response => {
        if (response) {
          const fnolSubmission = fnolApiClient.initializeSubmission(<FnolSubmission>response)
          commit('setSubmission', fnolSubmission)
        }
      }).finally(() => {
        commit('setSavingData', false)
      })
    },
    async submitSubmission({commit}): Promise<void> {
      commit('setSavingData', true)
      await fnolApiClient.submitSubmission().finally(() => {
        commit('setSavingData', false)
      })
    },
    async uploadFile({commit, state}, file: File): Promise<FnolSubmission | void> {
      const uploadableFile: UploadedFileStatus = new UploadedFileStatus(file.name, file.size, 'in progress')
      commit('addFileStatus', uploadableFile)

      const fileSizeInMB = parseFloat((uploadableFile.fileSize/1000000).toFixed(3))

      if (fileSizeInMB <= 33 && state.totalMB <= 33) {
        fnolApiClient.uploadFile(file).then(response => {
          if (response) {
            const fileUploadResponse: FileUploadResponse = response as FileUploadResponse
            if (fileUploadResponse.uploaded || fileUploadResponse.updated) {
              uploadableFile.status = 'success'
              uploadableFile.filePath = fileUploadResponse.filePath
            } else if (fileUploadResponse.infected) {
              uploadableFile.status = 'infected'
              commit('recalculateFileSize')
            } else if (fileUploadResponse.encrypted){
                uploadableFile.status = 'encrypted'
                commit('recalculateFileSize')
            } else {
              uploadableFile.status = 'failure'
              commit('recalculateFileSize')
            }
          }
        }).catch(() => {
          uploadableFile.status = 'failure'
          commit('recalculateFileSize')
        }).finally(() => {
          commit('updateFileStatus', uploadableFile)
        })
      }
    else {
        uploadableFile.status = 'failure'
      }
    },
    async deleteFile({commit}, fileStatus: UploadedFileStatus): Promise<FnolSubmission | void> {
      if (fileStatus.status !== 'success') {
        // For files that failed to upload, do not call the service to remove it
        commit('removeFileStatus', fileStatus.filePath)
        commit('recalculateFileSize')
      } else {
        fnolApiClient.removeFile(fileStatus.filePath).then(() => {
          commit('removeFileStatus', fileStatus.filePath)
          commit('recalculateFileSize')
        }).catch(() => {
          //TODO: Add any error handling if the file failed to delete
        })
      }
    }
  },
  mutations: {
    addFileStatus(state, value) {
      state.submission.uploadedFileStatuses.push(value)
    },
    updateFileStatus(state, value) {
      // remove the file from the array and re-add it (instead of mutating the array element) to trigger a Vue update
      const index: number =  state.submission.uploadedFileStatuses.findIndex(file => file.fileName === value.fileName)
      state.submission.uploadedFileStatuses.splice(index, 1, value)
    },
    removeFileStatus(state, value) {
      state.submission.uploadedFileStatuses = state.submission.uploadedFileStatuses.filter(fileStatus => fileStatus.filePath !== value)
    },
    recalculateFileSize(state) {
      state.totalMB = 0;
      state.submission.uploadedFileStatuses.forEach((ufs : UploadedFileStatus) => {
        if (ufs.status == "success" || ufs.status == "in progress") {
          const fileSizeInMB = parseFloat((ufs.fileSize / 1000000).toFixed(3))
          state.totalMB = parseFloat((state.totalMB + fileSizeInMB).toFixed(3))
        }
      })
    },
    setSavingData(state, value) {
      state.savingData = value
    },
    setNavigating(state, value) {
      state.navigating = value
    },
    setNavigatingNext(state, value) {
      state.navigatingNext = value
    },
    setNavigatingPrevious(state, value) {
      state.navigatingPrevious = value
    },
    setStartingOver(state, value) {
      state.startingOver = value
    },
    setKey(state, response) {
      state.siteKey = response
    },
    setIsRecaptchaTokenValid(state, response) {
      state.isRecaptchaTokenValid = response
    },
    setLoadRecaptchaWidget(state, response) {
      state.loadRecaptchaWidget = response
    },
    setSubmission(state, submission) {
      state.submission = submission
    },
    setDateOfAccident(state, dateOfAccident) {
      state.submission.accidentInformation.dateOfAccident = dateOfAccident
    },
    setInsuredName(state, insuredName) {
      state.submission.additionalInformation.insuredName = insuredName
    },
    setPolicyNumber(state, policyNumber) {
      state.submission.additionalInformation.policyNumber = policyNumber
    },
    setInjuredPersonFirstName(state, firstName) {
      state.submission.injuredInformation.injuredPersonInformation.firstName = firstName
    },
    setInjuredPersonLastName(state, lastName) {
      state.submission.injuredInformation.injuredPersonInformation.lastName = lastName
    },
    setInjuredPersonSuffix(state, suffix) {
      state.submission.injuredInformation.injuredPersonInformation.suffix = suffix
    },
    setInjuredPersonSuffixCode(state, suffixCode) {
      state.submission.injuredInformation.injuredPersonInformation.suffixCode = suffixCode
    },
    setInjuredPersonDateOfBirth(state, dateOfBirth) {
      state.submission.injuredInformation.injuredPersonInformation.dateOfBirth = dateOfBirth
    },
    setInjuredPersonGender(state, gender) {
      state.submission.injuredInformation.injuredPersonInformation.gender = gender
    },
    setInjuredPersonPhoneNumber(state, phoneNumber) {
      state.submission.injuredInformation.injuredPersonInformation.phoneNumber = phoneNumber
    },
    setInjuredPersonEmailAddress(state, emailAddress) {
      state.submission.injuredInformation.injuredPersonInformation.emailAddress = emailAddress
    },
    setInjuredPersonAddressLine1(state, addressLine1) {
      state.submission.injuredInformation.injuredPersonInformation.addressLine1 = addressLine1
    },
    setInjuredPersonAddressLine2(state, addressLine2) {
      state.submission.injuredInformation.injuredPersonInformation.addressLine2 = addressLine2
    },
    setInjuredPersonAddressCity(state, addressCity) {
      state.submission.injuredInformation.injuredPersonInformation.addressCity = addressCity
    },
    setInjuredPersonAddressState(state, addressState) {
      state.submission.injuredInformation.injuredPersonInformation.addressState = addressState
    },
    setInjuredPersonAddressStateCode(state, addressStateCode) {
      state.submission.injuredInformation.injuredPersonInformation.addressStateCode = addressStateCode
    },
    setInjuredPersonAddressZip(state, addressZip) {
      state.submission.injuredInformation.injuredPersonInformation.addressZip = addressZip
    },
    setInjuredPersonInformation(state, injuredPersonInformation) {
      state.submission.injuredInformation.injuredPersonInformation = injuredPersonInformation
    },
    setContactPersonFirstName(state, firstName) {
      state.submission.injuredInformation.contactPersonInformation.firstName = firstName
    },
    setContactPersonLastName(state, lastName) {
      state.submission.injuredInformation.contactPersonInformation.lastName = lastName
    },
    setContactPersonSuffix(state, suffix) {
      state.submission.injuredInformation.contactPersonInformation.suffix = suffix
    },
    setContactPersonSuffixCode(state, suffixCode) {
      state.submission.injuredInformation.contactPersonInformation.suffixCode = suffixCode
    },
    setContactPersonSameAsInjuredPerson(state, sameAsInjuredPerson) {
      state.submission.injuredInformation.contactPersonInformation.sameAsInjuredPerson = sameAsInjuredPerson
    },
    setContactInformationPersonSameAsInjuredPerson(state, sameContactInformationAsInjuredPerson) {
      state.submission.injuredInformation.contactPersonInformation.sameContactInformationAsInjuredPerson = sameContactInformationAsInjuredPerson
    },
    setContactPersonRelationshipToInjuredPerson(state, relationshipToInjuredPerson) {
      state.submission.injuredInformation.contactPersonInformation.relationshipToInjuredPerson = relationshipToInjuredPerson
    },
    setContactPersonPhoneNumber(state, phoneNumber) {
      state.submission.injuredInformation.contactPersonInformation.phoneNumber = phoneNumber
    },
    setContactPersonEmailAddress(state, emailAddress) {
      state.submission.injuredInformation.contactPersonInformation.emailAddress = emailAddress
    },
    setContactPersonAddressLine1(state, addressLine1) {
      state.submission.injuredInformation.contactPersonInformation.addressLine1 = addressLine1
    },
    setContactPersonAddressLine2(state, addressLine2) {
      state.submission.injuredInformation.contactPersonInformation.addressLine2 = addressLine2
    },
    setContactPersonAddressCity(state, addressCity) {
      state.submission.injuredInformation.contactPersonInformation.addressCity = addressCity
    },
    setContactPersonAddressState(state, addressState) {
      state.submission.injuredInformation.contactPersonInformation.addressState = addressState
    },
    setContactPersonAddressStateCode(state, addressStateCode) {
      state.submission.injuredInformation.contactPersonInformation.addressStateCode = addressStateCode
    },
    setContactPersonAddressZip(state, addressZip) {
      state.submission.injuredInformation.contactPersonInformation.addressZip = addressZip
    },
    setContactPersonInformation(state, contactPersonInformation) {
      state.submission.injuredInformation.contactPersonInformation = contactPersonInformation
    },
    setAccidentDescription(state, accidentDescription) {
      state.submission.accidentInformation.accidentDescription = accidentDescription
    },
    setNatureOfInjuryDescription(state, natureOfInjuryDescription) {
      state.submission.accidentInformation.natureOfInjuryDescription = natureOfInjuryDescription
    },
    setHasOtherPlan(state, hasOtherPlan) {
      state.submission.otherInsurance.hasOtherPlan = hasOtherPlan
    },
    setInsuranceCompanyName(state, insuranceCompanyName) {
      state.submission.otherInsurance.insuranceCompanyName = insuranceCompanyName
    },
    setPatientOrRepSignature(state, patientOrRepSignature){
      state.submission.medicalRelease.patientOrPatientRepSignature = patientOrRepSignature;
    },
    setPatientRepName(state, patientRepName){
      state.submission.medicalRelease.patientRepName = patientRepName
    },
    setPatientRepRelationship(state, patientRepRelationship){
      state.submission.medicalRelease.patientRepRelationship = patientRepRelationship
    },
    setDateOfRelease(state, dateOfRelease) {
      state.submission.medicalRelease.dateOfRelease = dateOfRelease
    },
    setCanUserNavigate(state, canUserNavigate) {
      state.canUserNavigate = canUserNavigate;
    },
    saveCommonData(state) {
      if(!state.submission.commonData) {
        state.submission.commonData = new CommonData();
      }
      if(!state.submission.commonData.claimReporter) {
        state.submission.commonData.claimReporter = new CommonContact();
      }
      state.submission.commonData.claimReporter.firstName = state.submission.injuredInformation.contactPersonInformation.firstName;
      state.submission.commonData.claimReporter.lastName = state.submission.injuredInformation.contactPersonInformation.lastName;
      state.submission.commonData.claimReporter.emailAddress = state.submission.injuredInformation.contactPersonInformation.emailAddress;
      state.submission.commonData.claimReporter.phone = state.submission.injuredInformation.contactPersonInformation.phoneNumber;

      if(!state.submission.commonData.claimPreparer) {
        state.submission.commonData.claimPreparer = new CommonContact();
      }
      state.submission.commonData.claimPreparer.firstName = state.submission.injuredInformation.contactPersonInformation.firstName;
      state.submission.commonData.claimPreparer.lastName = state.submission.injuredInformation.contactPersonInformation.lastName;
      state.submission.commonData.claimPreparer.emailAddress = state.submission.injuredInformation.contactPersonInformation.emailAddress;
      state.submission.commonData.claimPreparer.phone = state.submission.injuredInformation.contactPersonInformation.phoneNumber;

    }
  }
})
