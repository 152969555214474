
import Vue from 'vue';
import {Component} from "vue-property-decorator";
import Header from '@/views/Header.vue'
import MobileStepper from "@/components/MobileStepper.vue";
import MobileFooter from "@/components/MobileFooter.vue";
import DesktopFooter from "@/components/DesktopFooter.vue";
import DesktopStepper from '@/components/DesktopStepper.vue'


@Component({
  components: {DesktopStepper, DesktopFooter, MobileFooter, Header, MobileStepper}
})
export default class App extends Vue {
  get hasFooterCircle() {
    return this.$route.name === 'LandingPage'
  }
}
