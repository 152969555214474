import { render, staticRenderFns } from "./DesktopStepper.vue?vue&type=template&id=6622f630&scoped=true"
import script from "./DesktopStepper.vue?vue&type=script&lang=ts"
export * from "./DesktopStepper.vue?vue&type=script&lang=ts"
import style0 from "./DesktopStepper.vue?vue&type=style&index=0&id=6622f630&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6622f630",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VContainer,VIcon})
