export class ContactPersonInformation {
  sameAsInjuredPerson = false
  sameContactInformationAsInjuredPerson = false
  firstName!: string
  lastName!: string
  suffix!: string
  suffixCode!: string
  addressLine1!: string
  addressLine2!: string
  addressCity!: string
  addressState!: string
  addressStateCode!: string
  addressZip!: string
  phoneNumber!: string
  emailAddress!: string
  relationshipToInjuredPerson!: string
  relationshipToInjuredPersonCode!: string
}
