
import Component from 'vue-class-component'
import { Vue } from 'vue-property-decorator'

@Component
export default class DesktopStepper extends Vue {

  filledCircle = 'mdi-checkbox-blank-circle'
  emptyCircle = 'mdi-checkbox-blank-circle-outline'
  checkedCircle = 'mdi-check-circle'

  get isInProgress(): boolean {
    switch (this.$route.name) {
      case 'GetStarted':
      case 'InjuredInformation':
      case 'OtherInsurance':
      case 'AccidentInformation':
      case 'MedicalRelease':
      case 'FraudWarning':
      case 'SupportingDocuments':
        return true
      default:
        return false
    }
  }

  get getStartedIcon(): string {
    switch (this.$route.name) {
      case 'GetStarted':
        return this.filledCircle
      default:
        return this.checkedCircle
    }
  }

  get injuredInformationIcon(): string {
    switch (this.$route.name) {
      case 'GetStarted':
        return this.emptyCircle
      case 'InjuredInformation':
        return this.filledCircle
      default:
        return this.checkedCircle
    }
  }

  get otherInsuranceIcon(): string {
    switch (this.$route.name) {
      case 'GetStarted':
      case 'InjuredInformation':
        return this.emptyCircle
      case 'OtherInsurance':
        return this.filledCircle
      default:
        return this.checkedCircle
    }
  }

  get accidentInformationIcon(): string {
    switch (this.$route.name) {
      case 'GetStarted':
      case 'InjuredInformation':
      case 'OtherInsurance':
        return this.emptyCircle
      case 'AccidentInformation':
        return this.filledCircle
      default:
        return this.checkedCircle
    }
  }

  get medicalReleaseIcon(): string {
    switch (this.$route.name) {
      case 'GetStarted':
      case 'InjuredInformation':
      case 'OtherInsurance':
      case 'AccidentInformation':
        return this.emptyCircle
      case 'MedicalRelease':
        return this.filledCircle
      default:
        return this.checkedCircle
    }
  }

  get fraudWarningIcon(): string {
    switch (this.$route.name) {
      case 'GetStarted':
      case 'InjuredInformation':
      case 'OtherInsurance':
      case 'AccidentInformation':
      case 'MedicalRelease':
        return this.emptyCircle
      case 'FraudWarning':
        return this.filledCircle
      default:
        return this.checkedCircle
    }
  }

  get supportingDocumentsIcon() : string {
    switch (this.$route.name) {
      case 'GetStarted':
      case 'InjuredInformation':
      case 'OtherInsurance':
      case 'AccidentInformation':
      case  'MedicalRelease':
      case 'FraudWarning':
        return this.emptyCircle
      case 'SupportingDocuments':
        return this.filledCircle
      default:
        return this.checkedCircle
    }
  }
}

