import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'LandingPage',
    component: () =>
        import(/* webpackChunkName: "LandingPage" */ '../views/LandingPage.vue')
  },
  {
    path: '/access-agreement',
    name: 'AccessAgreement',
    component: () =>
        import(/* webpackChunkName: "AccessAgreement" */ '../views/AccessAgreement.vue')
  },
  {
    path: '/get-started',
    name: 'GetStarted',
    component: () =>
        import(/* webpackChunkName: "GetStarted" */ '../views/GetStarted.vue')
  },
  {
    path: '/injured-information',
    name: 'InjuredInformation',
    component: () =>
        import(/* webpackChunkName: "InjuredInformation.ts" */ '../views/InjuredInformation.vue')
  },
  {
    path: '/accident-information',
    name: 'AccidentInformation',
    component: () =>
        import(/* webpackChunkName: "AccidentInformation.ts" */ '../views/AccidentInformation.vue')
  },
  {
    path: '/medical-release',
    name: 'MedicalRelease',
    component: () =>
        import(/* webpackChunkName: "MedicalRelease.ts" */ '../views/MedicalRelease.vue')
  },
  {
    path: '/fraud-warning',
    name: 'FraudWarning',
    component: () =>
        import(/* webpackChunkName: "FraudRelease.ts" */ '../views/FraudWarning.vue')
  },
  {
    path: '/review-and-submit',
    name: 'ReviewAndSubmit',
    component: () =>
        import(/* webpackChunkName: "ReviewAndSubmit.ts" */ '../views/ReviewAndSubmit.vue')
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: () =>
        import(/* webpackChunkName: "Confirmation.ts" */ '../views/Confirmation.vue')
  },
  {
    path: '/other-insurance',
    name: 'OtherInsurance',
    component: () =>
        import(/* webpackChunkName: "OtherInsurance.ts" */ '../views/OtherInsurance.vue')
  },
  {
    path: '/supporting-documents',
    name: 'SupportingDocuments',
    component: () =>
        import(/* webpackChunkName: "SupportingDocuments.ts" */ '../views/SupportingDocuments.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
