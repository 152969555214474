import { Status } from '@/models/typelists/Status'
import { AccidentInformation } from '@/models/AccidentInformation'
import { AdditionalInformation } from '@/models/AdditionalInformation'
import { InjuredInformation } from '@/models/InjuredInformation'
import { OtherInsurance } from '@/models/OtherInsurance'
import UploadedFileStatus from '@/models/UploadedFileStatus'
import { CommonData } from '@/models/CommonData'
import { MedicalRelease } from '@/models/MedicalRelease'


export class FnolSubmission {
  reportNumber!: string
  status!: Status
  fraudWarningAcknowledged = false;
  commonData: CommonData = new CommonData()
  accidentInformation: AccidentInformation = new AccidentInformation()
  medicalRelease: MedicalRelease = new MedicalRelease()
  additionalInformation: AdditionalInformation = new AdditionalInformation()
  injuredInformation: InjuredInformation = new InjuredInformation()
  otherInsurance: OtherInsurance = new OtherInsurance()
  uploadedFileStatuses: UploadedFileStatus[] = []
}
